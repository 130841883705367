<script>
  import Router, { location } from "svelte-spa-router";
  /*Components*/
  import Navbar from "./components/navbar.svelte";
  import Footer from "./components/footer.svelte";
  /*Routes*/
  import Contact from "./routes/Contact.svelte";
  import Visitors from "./routes/Visitors.svelte";
  import Home from "./routes/Home.svelte";
  import Satzung from "./routes/Satzung.svelte";
  import Impressum from "./routes/Impressum.svelte";

  const routes = {
    "/": Home,
    "/visitors": Visitors,
    "/contact": Contact,
    "/satzung": Satzung,
    "/impressum": Impressum,
  };
</script>

<Navbar />
{#if $location === "/contact"}
  <div class="contact-div" style="display: flex; justify-content: center;">
    <Router {routes} />
  </div>
{:else}
  <Router {routes} />
{/if}
<Footer />

<style></style>
