<h1>Unser Verein</h1>
<p>
  Willkommen bei unserem Verein! Hier finden Sie Informationen über unsere
  Mitglieder und Aktivitäten.
</p>
<table class="table table-bordered">
  <tbody>
    <tr>
      <th scope="row">1. Vorstand</th>
      <td>Heidi Jach</td>
    </tr>
    <tr>
      <th scope="row">2. Vorstand</th>
      <td>Stefanie Schelmbauer</td>
    </tr>
    <tr>
      <th scope="row">Kassier</th>
      <td>Katrin Etzel</td>
    </tr>
    <tr>
      <th scope="row">Sportwart</th>
      <td>Stefan Deinhardt</td>
    </tr>
    <tr>
      <th scope="row">Schriftführer</th>
      <td>Stefanie Schelmbauer</td>
    </tr>
  </tbody>
</table>

<style>
  table {
    margin-bottom: 0;
  }
  th,
  td {
    text-align: left;
    width: 50%;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 10;
  }
  @media (min-width: 1300px) {
    table {
      font-size: 160%;
    }
  }
</style>
