<script>
</script>

<main class="contact">
  <h1>Kontakt</h1>
  <p>
    Bei Fragen rund um unseren Verein oder zum Tennissport, bitte das unten
    stehende Formualr ausfüllen!
  </p>
  <form action="https://api.web3forms.com/submit" method="POST">
    <input
      type="hidden"
      name="access_key"
      value="5126766f-aac8-4189-baf0-77da76c98f9b"
    />
    <input placeholder="Name" type="text" name="name" required />
    <input placeholder="Email" type="email" name="email" required />
    <textarea placeholder="Message" name="message" required></textarea>
    <input
      type="checkbox"
      name="botcheck"
      class="hidden"
      style="display: none;"
    />
    <button type="submit">Abschicken!</button>
  </form>
</main>

<style>
  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    max-width: 450px;

    margin: 1% 2%;
    margin-top: 20px;
    border: 3px solid black;
    border-radius: 15px;
    padding: 30px;
    text-align: center;
    background: linear-gradient(
      to bottom right,
      #bcf413a9,
      #94c10ca9,
      #bcf413a9
    );
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  input,
  textarea {
    margin-bottom: 10px;
    padding: 10px;
    width: 300px;
    border: 2px dashed black;
    border-radius: 15px;
  }
  button {
    padding: 10px 20px;
    color: black;
    border: 2px dashed black;
    border-radius: 15px;
    cursor: pointer;
    background-color: #bcf413a9;
  }
</style>
