<script>
  export let title;
  export let content;
  export let img;
  export let author;
  export let download;

  let showMore = false;
</script>

<div class="news">
  <hr />
  <div class="n n-1">
    <div class="text">
      <h2>{title}</h2>
    </div>
    {#if img}
      <div class="image">
        <p>
          {#if showMore}
            {content}
          {:else}
            {content.split(" ", 20).join(" ")}...
          {/if}
        </p>
        {#if showMore}
        <div class="show_more_img">
          <img src={img} alt={title} />
          <img src="/img/6.jpeg" alt="Bild_Fest">
        </div>
        {/if}
        <div class="buttons">
          <button id="showMore" on:click={() => (showMore = !showMore)}>
            {#if showMore}
              Weniger anzeigen
            {:else}
              Mehr anzeigen
            {/if}
          </button>
          <button id="download">
            <a id="download_image_link" href={download} download
              ><img
                id="download_image"
                alt="DOWNLOAD"
                src="/img/download.png"
              /></a
            >
          </button>
        </div>
      </div>
      <p id="author">Autor: {author}</p>
    {/if}
  </div>
</div>

<style>
  .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image p {
    text-align: justify;
  }
  .n {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    align-items: center;
  }
  .n img {
    width: 100%;
    margin-top: 10px;
    height: auto;
  }
  .n h2 {
    margin-bottom: 10px;
  }

  #showMore {
    background-color: #bcf413a9;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
  }

  #author {
    margin-top: 10px;
    width: 100%;
    float: right;
    color: #333;
    margin-bottom: 0;
  }

  #download {
    background-color: #bcf413a9;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-left: 10px;
    cursor: pointer;
  }

  #download_image_link {
    width: 40px;
    height: 40px;
    margin-top: 0;
  }

  #download_image {
    width: 20px;
    height: 20px;
    margin-top: 0;
  }

  @media (min-width: 777px) {
    .n img {
      width: 50%;
      height: auto;
    }
    .news p {
      margin: 0 20%;
    }
    .show_more_img {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .show_more_img img {
      width: 40%;
      height: auto;
      margin: 10px;
    }
  }
</style>
