<div class="visiotors">
  <h1 id="welcome">Gastspieler</h1>
  <p>
    Willkommen auf unserer Tennisanlage! Hier können Sie als Gastspieler unsere
    Plätze nutzen und entspannen.
  </p>
  <hr />
  <div class="preise">
    <h1>Preise</h1>
    <p>
      <span style="font-weight: bold;">5€/h pro Spieler und Platz.</span> Dies
      umfasst die Nutzung der Tennisplätze sowie der <u>Duschen und WCs</u>.
      Zugang mit dem ausgeliehenen Schlüssel.
    </p>
  </div>
  <hr />
  <div class="my_container">
    <div class="info">
      <h1>Schlüsselabholung</h1>
      <p>
        Der Schlüssel kann während der unten stehenden Öffnungszeiten hier: <a
          href="https://www.google.com/maps/dir/?api=1&destination=L%C3%B6wen+Apotheke&destination_place_id=ChIJz2mgg9D_l0cRXlBvnInsv28"
          >Löwenapotheke</a
        > abgeholt werden.
      </p>
      <p id="pfand">
        (Es wird ein Pfand von 10€ für den Schlüssel erhoben, der bei Rückgabe
        erstattet wird.)
      </p>
    </div>
    <hr class="small_hr" />
    <div class="opening-times">
      <h2>Öffnungszeiten:</h2>
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th scope="row">Mo</th>
            <td>08:15 - 12:15 || 14:00 - 18:15</td>
          </tr>
          <tr>
            <th scope="row">Di</th>
            <td>08:15 - 12:15 || 14:00 - 18:15</td>
          </tr>
          <tr>
            <th scope="row">Mi</th>
            <td>08:15 - 12:15</td>
          </tr>
          <tr>
            <th scope="row">Do</th>
            <td>08:15 - 12:15 || 14:00 - 18:15</td>
          </tr>
          <tr>
            <th scope="row">Fr</th>
            <td>08:15 - 12:15 || 14:00 - 18:15</td>
          </tr>
          <tr>
            <th scope="row">Sa</th>
            <td>08:15 - 12:15</td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr class="small_hr" />
  </div>
  <img id="requet" alt="deko-bild" src="/img/requet.png"/>
</div>

<style>
  .visiotors {
    margin: 1% 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  h1 {
    margin-bottom: 10px;
    margin-top: 20px;
  }
  #welcome {
    font-size: 4rem;
  }
  p {
    font-size: 1.5rem;
    margin-bottom: 0;
  }

  /* opening times */
  .my_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;
  }

  .opening-times {
    margin-top: 5px;
    width: 50%;
    margin-bottom: 30px;
  }
  .opening-times table {
    margin-bottom: 0;
    text-align: center;
  }
  .opening-times th {
    font-size: 2rem;
  }
  .opening-times td {
    font-size: 1.5rem;
  }
  .opening-times h2 {
    float: left;
  }
  .info {
    width: 50%;
    margin: 0;
    text-align: center;
  }

  #pfand {
    font-size: 1rem;
    margin: 0;
    color: rgb(137, 0, 0);
  }

  /* preise */
  .preise {
    width: 100%;
    margin-top: 0;
  }
  .preise h1 {
    margin-top: 0;
  }
  /* media queries */
  @media (max-width: 1300px) {
    h1,
    #welcome {
      font-size: 2rem;
      margin-bottom: 10px;
    }
    p {
      font-size: medium;
      margin: 0;
    }

    /* opening times */
    .opening-times {
      width: 100%;
      margin-bottom: 10px;
    }
    .opening-times th {
      font-size: 1.2rem;
    }
    .opening-times td {
      font-size: 1rem;
    }
    #pfand {
      font-size: 0.8rem;
      margin-top: 10px;
    }

    /* info */
    .info {
      width: 100%;
      margin-bottom: 0;
      margin-top: 0;
    }
    .info h1 {
      margin-top: 0;
    }
    .my_container {
      flex-direction: column;
      margin-top: 0;
    }
  }
  @media (min-width: 1300px) {
    .small_hr {
      display: none;
    }
  }

  #requet {
    width: 80%;
    max-width: 600px !important;
    height: auto;
    margin-top: 10px;
  }
</style>
