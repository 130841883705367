<script>
  import Carousel from "../components/carousel.svelte";
  import Verein from "../components/verein.svelte";
  import News from "../components/news.svelte";
  import Mitgliedschaft from "../components/mitgliedschaft.svelte";
</script>

<main>
  <div class="welcome">
    <h1>Willkommen beim TC Amorbach</h1>
    <p>
      Herzlich willkommen auf der Website des TC Amorbach! Wir freuen uns, dass
      Sie den Weg zu uns gefunden haben. Unser Tennisclub bietet eine Vielzahl
      von Aktivitäten und Veranstaltungen für Tennisbegeisterte jeden Alters und
      jeder Spielstärke. Schauen Sie sich um und erfahren Sie mehr über unsere
      Angebote und unseren Verein.
    </p>
  </div>
  <hr />
  <div class="carousel">
    <Carousel />
  </div>
  <hr />
  <div class="sponsors">
    <h1>Unsere Sponsoren</h1>
    <p>Wir danken unseren Sponsoren für ihre großzügige Unterstützung.</p>
    <div class="sponsor-images">
      <img src="/img/merk.jpg" alt="MERK logo" />
      <img src="/img/laeuner.png" alt="Läuner logo" />
      <img src="/img/sponsor.jpg" alt="logo schulzstiftung">
    </div>
  </div>
  <hr />
  <div class="news">
    <h1>Aktuelles</h1>
    <p>Hier sind aktuelle Informationen.</p>
    <News
      img="/img/Foto_Fest.jpg"
      title="Sommerfest beim TC GW Amorbach"
      content="Am 21. September fand bei herrlichstem Sonnenschein das Spätsommerfest des Tennisvereins Grün-Weiß Amorbach statt. Zuvor hatten viele fleißige Helfer die Sportanlage auf Vordermann gebracht, Pavillons aufgebaut und alles liebevoll geschmückt.
Das Fest war gut besucht: Neben den 40 angemeldeten kamen noch etliche spontane Gäste dazu. Es begann um 11 Uhr mit einem Schnuppertraining für Kinder ab 7 Jahren und auch ältere Kinder und Erwachsene durften den Tennissport ausprobieren. Für die kleinsten Gäste wurden Ballspiele angeboten. Ab dem frühen Nachmittag wurde dann „richtig“ Tennis gespielt: Zunächst traten unsere Vereinskinder in einem Schleifchenturnier gegeneinander an, darauf folgte ein Mixed-Schleifchenturnier für Vereinsmitglieder und Freunde. Die Teilnahme war mit 16 Paaren erfreulich hoch. Es wurden bei jeder Begegnung 2 Match-Tiebreaks (also bis 10 Punkte) gespielt und am Schluss war das Ergebnis richtig knapp.
Den ganzen Tag über konnten sich die Anwesenden mit Speisen und Getränken versorgen, gegen Abend wurde gegrillt und es gab Stockbrot am Lagerfeuer. Es war ein rundum schöner Tag!
Vielleicht haben einige „Neulinge“ die Lust auf Tennis entdeckt und kommen wieder.
"
      author="Heidi Jach"
      download="/news/1.pdf"
    />
  </div>
  <hr />
  <div class="verein">
    <Verein />
  </div>
  <hr />
  <img id="frosch" src="/img/frosch.jpg" alt="Bild" />
  <hr class="small-hr"/>
  <div class="mitgliedschaft">
    <Mitgliedschaft />
  </div>
  <hr />
  <div class="vermietung">
    <section>
      <h1>Platzvermietung</h1>
      <p>
        Das Tennisheim kann für private Veranstaltungen gemietet werden! <br />
        Melden Sie sich hierzu gerne bei uns: <a href="/#/contact">KONTAKT</a>
      </p>
      <style>
        h1 {
          margin-top: 0;
          margin-bottom: 10px;
        }
        p {
          margin-bottom: 0px !important;
        }
        .vermietung section a {
          color: blue;
          font-weight: bold;
        }
        @media (min-width: 1300px) {
          h2 {
            font-size: 4em;
          }
        }
      </style>
    </section>
  </div>
  <hr />
  <iframe
    title="map"
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3179.405409017353!2d9.215841!3d49.6373821!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4797ffc5f40a94ed%3A0x41c067376eff7da9!2sKirchzeller%20Str.%202%2C%2063916%20Amorbach!5e1!3m2!1sde!2sde!4v1728749584728!5m2!1sde!2sde"
    width="600"
    height="450"
    style="border:0;"
    allowfullscreen=""
    loading="lazy"
    referrerpolicy="no-referrer-when-downgrade"
  ></iframe>
</main>

<style>
  main {
    margin: 1% 2%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  /* Welcome */
  .welcome {
    text-align: center;
  }
  .welcome h1 {
    font-size: 4rem;
    margin-bottom: 20px;
  }
  .welcome p {
    font-size: 1.5rem;
    margin: 0 20%;
    margin-bottom: 0;
  }

  /* Sponsors */
  .sponsor-images {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sponsors h1 {
    margin-bottom: 10px;
  }

  .sponsors p {
    margin: 0;
  }

  /* Verein */
  .verein,
  .mitgliedschaft {
    width: 100%;
  }

  /* News */
  .news h1 {
    margin-bottom: 10px;
  }
  .news p {
    margin: 0;
  }

  .carousel {
    margin-bottom: 0;
  }

  /* Media Queries */
  @media (max-width: 1300px) {
    /* Welcome */
    .welcome {
      margin-bottom: 0;
    }
    .welcome h1 {
      font-size: 2rem;
      margin-bottom: 10px;
    }
    .welcome p {
      font-size: medium;
      margin: 0;
    }

    /* Sponsors */
    .sponsors {
      margin-bottom: 0;
    }
    .sponsor-images {
      flex-direction: column;
      background-color: none;
      margin-top: 0;
    }
    .sponsor-images img {
      width: 90%;
      margin: 10px 0;
    }

    iframe {
      width: 100%;
      height: 300px;
    }
  }
  @media (min-width: 1300px) {
    .carousel {
      min-height: 400px;
    }
    #frosch {
      display: none;
    }
    .small-hr {
      display: none;
    }
  }
  #frosch {
    width: 100%;
    height: auto;
    max-width: 500px;
  }
</style>
