<script>
  import { files } from "../filenames.js";
</script>

<div
  id="carouselExampleIndicators"
  class="carousel slide shadow"
  data-bs-ride="carousel"
>
  <div class="carousel-indicators">
    {#each files as file, index}
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to={index}
        class={index === 0 ? "active" : ""}
        aria-current={index === 0 ? "true" : "false"}
        aria-label={"Slide " + (index + 1)}
      ></button>
    {/each}
  </div>
  <div class="carousel-inner">
    {#each files as file, index}
      <div class={"carousel-item" + (index === 0 ? " active" : "")}>
        <img src={file} class="d-block w-100" alt={"Slide " + (index + 1)} />
      </div>
    {/each}
  </div>
  <button
    class="carousel-control-prev"
    type="button"
    data-bs-target="#carouselExampleIndicators"
    data-bs-slide="prev"
  >
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
    class="carousel-control-next"
    type="button"
    data-bs-target="#carouselExampleIndicators"
    data-bs-slide="next"
  >
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

<style>
  @media (min-width: 600px) {
    .carousel {
      max-width: 600px;
    }
  }
</style>
