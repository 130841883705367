<header>
  <div class="brand">
    <h1>TC - Amorbach</h1>
    <img src="/img/Logo_TCA_rund.png" alt="Logo"/>
  </div>
  <nav>
    <ul>
      <li><a href="/#/">Home</a></li>
      <span class="vr"></span>
      <li class="middle-link">
        <a class="middle-link" href="/#/visitors">Gastspieler</a>
      </li>
      <span class="vr"></span>
      <li><a href="/#/contact">Kontakt</a></li>
    </ul>
  </nav>
</header>

<style>
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    margin: 1% 2%;
    margin-bottom: 40px;
    border: none;
    border-radius: 30px;
    background-image: url("/img/1.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    color: white;
    box-shadow: 10px 14px 18px rgba(0, 0, 0, 0.4);
  }
  .brand h1 {
    font-size: 2.8rem;
    padding: 20px 40px;
    margin: 0;
  }
  .brand {
    background-color: #bcf413b7;
    border: none;
    border-radius: 30px;
    margin-left: 100px;
    display: flex;
    align-items: center;
  }
  .brand img {
    width: 80px;
    height: 80px;
    margin: 10px;
    margin-left: 20px;
  }
  nav ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 100px;
    border-radius: 40px;
    padding: 10px 30px;
    background-color: #bcf413a9;
    margin-bottom: 0;
  }
  nav ul li {
    list-style: none;
    margin: 0 20px;
    font-size: 1.8rem;
  }
  nav ul li a {
    text-decoration: none;
    color: white;
    transition: ease 0.8s;
  }
  nav ul li a:hover {
    color: #333;
  }
  .middle-link {
    margin: 0;
  }
  .vr {
    border: 2px solid white;
    height: 50px;
    width: 2px;
    margin: 0 20px;
    z-index: 99;
  }

  @media (max-width: 1300px) {
    header {
      margin: 5px;
      padding: 10px;
      flex-direction: column;
      background-image: url("/img/1_medium.jpg");
    }
    nav ul {
      margin: 0;
      margin-top: 20px;
      padding: 5px;
      width: 100%;
      background: none;
    }
    nav ul li {
      font-size: 1.2rem;
      margin: 0px 1%;
    }
    nav ul li a:hover {
      background-color: #bcf413a9;
      border-radius: 10px;
      padding: 10px;
    }
    .middle-link {
      margin: 0px 1%;
    }
    .brand {
      margin: 0;
    }
    .brand h1 {
      font-size: 1.8rem;
      padding: 8px 30px;
    }
    .vr {
      margin: 0px 6px;
    }
  }
</style>
